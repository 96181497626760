/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./Product.css";
import productImg from "../images/product-image.png";
function Product() {
  return (
    <div className="col-sm-6">
      <div id="product-box">
        <div name="User 1" class="layer">
          <div className="content-product-box h-auto">
            <p className="color-text">Smart Watch</p>
            <h1 className="product-large-text">fire Bolt </h1>
            <p className="product-small-text">scan the Device QR to connect</p>
            <button className="product-button">Scan</button>
          </div>
          <div className="image-box">
            <img className="productimage" src={productImg} />
          </div>
        </div>
        <div name="User 2" class="layer"></div>
        <div name="User 3" class="layer"></div>
      </div>
    </div>
  );
}

export default Product;
