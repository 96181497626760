import React from "react";
import "./Form.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

function Form() {
  return (
    <div className="form-box">
      <div className="inner-form-box">
        <h1 className="form-heading">Add Details</h1>
        <form>
          <div className="">
            <div className="mt-2">
              <input
                className="rounded-full block w-full border-0 p-3 font-medium"
                type="text"
                name="name"
                placeholder="Name"
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="mt-2">
                <input
                  className="rounded-full block w-full border-0 p-3 font-medium"
                  type="text"
                  name="name"
                  placeholder="Age"
                />
              </div>
              <div className="mt-2">
                <input
                  className="rounded-full block w-full border-0 p-3 font-medium"
                  type="text"
                  name="name"
                  placeholder="Height"
                />
              </div>
            </div>
            <div className="mt-2 select-box">
              <FontAwesomeIcon className="icon" icon={faArrowDown} size="1x" />
              <select className="bg-white rounded-full block w-full border-0 p-3 font-medium">
                <option>Are You a Gym Member ?</option>
                <option>Yes</option>
                <option>No</option>
              </select>
            </div>
            <div className="mt-2 select-box">
              <FontAwesomeIcon className="icon" icon={faArrowDown} size="1x" />
              <select className="bg-white rounded-full block w-full border-0 p-3 font-medium">
                <option>Do You eat Meat ?</option>
                <option>Yes</option>
                <option>No</option>
              </select>
            </div>
            <div className="mt-2 select-box">
              <FontAwesomeIcon className="icon" icon={faArrowDown} size="1x" />
              <select className="bg-white rounded-full block w-full border-0 p-3 font-medium">
                <option>Are You a Cyclist ?</option>
                <option>Yes</option>
                <option>No</option>
              </select>
            </div>
            <div className="mt-2 select-box">
              <input
                className="form-button text-white rounded-full block w-full border-0 p-5 font-medium cursor-pointer"
                type="submit"
                name="name"
                value="Save Details"
                placeholder="Height"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Form;
