import React from "react";
import "./Nav.css";
function Nav() {
  return (
    <div className="nav-box p-2">
      <h2 className="active text-size font-bold">Connected Devices</h2>
      <h2 className="deactive text-size">Statistics</h2>
    </div>
  );
}

export default Nav;
