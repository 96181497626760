/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./Header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import profile from "../images/Rectangle 7.png";

function Header() {
  return (
    <div className="flex justify-between align-middle items-center">
      <div className="icon-box text-center p-4 leading-6">
        <FontAwesomeIcon icon={faArrowLeft} size="1x" />
      </div>
      <div className="img-box">
        <img src={profile} />
      </div>
    </div>
  );
}

export default Header;
