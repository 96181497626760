import "./App.css";
import Detail from "./components/Detail";
import Form from "./components/Form";
import Header from "./components/Header";
import Nav from "./components/Nav";
import Product from "./components/Product";

function App() {
  return (
    <div className="container">
      <div className="row">
        <div className="main-box bg-white overflow-hidden">
          <Header />
          <h1 className="primerycolor p-4">
            Your <br />
            Fitness tracker
          </h1>
          <Nav />
          <div className="row">
            <Product />

            <Detail />
          </div>
          <Form />
        </div>
      </div>
    </div>
  );
}

export default App;
