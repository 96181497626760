import React from "react";
import "./Detail.css";
import Pie from "./Pie";
function Detail() {
  return (
    <div className="col-sm-6">
      <div className="detail-box">
        <div className="row">
          <div className="col-xs-6">
            <div className="black-box">
              <Pie id="cr" percentage={40} />
              <div className="context">
                <h1 className="font-semibold">5,233</h1>
                <p className="small-text-orange">Steps</p>
              </div>
            </div>
          </div>
          <div className="col-xs-6">
            <div className="button-box">
              <div className="colored-box">
                <h3>
                  <span className="small-text">Calories</span>
                  <br />
                  <span className="large-text">1200 Kcal</span>
                </h3>
              </div>

              <div className="colored-box">
                <h3>
                  <span className="small-text-1">water</span>
                  <br />
                  <span className="large-text">1.8 ltrs</span>
                </h3>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Detail;
